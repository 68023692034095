<template>
  <div>
    <PaymentTable />
  </div>
</template>

<script>
import PaymentTable from "@employer/PaymentCompleteTable";
export default {
  components: {
    PaymentTable,
  },
};
</script>
